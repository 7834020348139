<template>
  <div id="templates-subview">
    <div class="prose prose-md md:prose-lg">
      <h1>Templates</h1>
    </div>

    <div class="p-4 mt-12 mb-4 border border-neutral border-solid rounded-lg">
      <div class="flex flex-row flex-wrap items-center mb-4">
        <span class="inline-block mr-4 text-5xl flaticon-data" />
        <h3 class="flex-1 text-xl font-semibold">FLEX Enrollment Template</h3>
        <div class="w-full my-2 text-center sm:my-0 sm:w-auto">
          <Button
            class="mx-2 my-0"
            sml
            trailing="flaticon-down"
            href="/Enrollment Template.xlsx"
            download
          >
            Download Here
          </Button>
          <Button class="mx-2 my-0" sml to="/support/articles/360043060233">
            How-To Help
          </Button>
        </div>
      </div>
      <div class="max-w-full prose-sm prose">
        <ul>
          <li>
            Please fill this template out for the new plan year, and then
            throughout the year use this same template to communicate any new
            hires, changes, or terms.
          </li>
          <li>
            If you use a carrier to send us files please make sure that they
            identify it as such. For example "ABCCompanyOE2018".
          </li>
        </ul>
      </div>
    </div>
    <div
      class="p-4 mb-4 bg-neutral-light border border-neutral border-solid rounded-lg"
    >
      <div class="flex flex-row flex-wrap items-center mb-4">
        <span class="inline-block mr-4 text-5xl flaticon-files-and-folders" />
        <h3 class="flex-1 text-xl font-semibold">Contribution Template</h3>
        <div class="w-full my-2 text-center sm:my-0 sm:w-auto">
          <Button
            class="mx-2 my-0"
            sml
            trailing="flaticon-down"
            href="/Contribution Template.xlsx"
            download
          >
            Download Here
          </Button>
          <Button class="mx-2 my-0" sml to="/support/articles/360042588034">
            How-To Help
          </Button>
        </div>
      </div>
      <div class="max-w-full prose-sm prose">
        <ul>
          <li>
            We are providing you with the contribution template that will need
            to be submitted to RMR for each pay period throughout the year.
            <ul>
              <li>
                <em>
                  If your account is set to auto-post you DO NOT need to provide
                  this file each pay period.
                </em>
              </li>
            </ul>
          </li>
          <li>
            For smaller groups with 100 or fewer employees, see
            <em
              ><a
                :href="
                  require('@/assets/forms/Contribution Processing Instructions (Small Groups).pdf')
                "
                download
                class="inline-link"
                >instructions</a
              ></em
            >
            for processing contributions directly into our system.
          </li>
        </ul>
      </div>
    </div>
    <div class="p-4 mb-4 border border-neutral border-solid rounded-lg">
      <div class="flex flex-row flex-wrap items-center mb-4">
        <span class="inline-block mr-4 text-5xl flaticon-files" />
        <h3 class="flex-1 text-xl font-semibold">ACH Authorization Form</h3>
        <div class="w-full my-2 text-center sm:my-0 sm:w-auto">
          <Button
            class="mx-2 my-0"
            sml
            trailing="flaticon-down"
            href="/RMR ACH Authorization Form.pdf"
            download
          >
            Download Here
          </Button>
        </div>
      </div>
      <div class="max-w-full prose-sm prose">
        <ul>
          <li>
            This will authorize RMR to pull funds for plan funding and monthly
            administrative invoices.
          </li>
          <li>
            Download to fill out and send it to finance@rmrbenefits.com and your
            account manager.
          </li>
          <li>
            If your information changes, please complete a new form and send it
            to your account manager.
          </li>
        </ul>
      </div>
    </div>
    <div>
      Looking for COBRA Templates? Click
      <router-link class="inline-link" to="/employer-resources/cobra-resources"
        >here</router-link
      >
    </div>
  </div>
</template>

<script>
import Button from "@/components/ui/Button.vue";

export default {
  components: {
    Button
  }
};
</script>
